import React, { useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { toast } from "react-toastify";
import { DataContext } from "./dataContext";
import { UserContext } from "./userContext";
import {
    AccessPermissionModuleNames,
    AccessPermissionModules, AttributesCategoryGroup,
    MembersContextCaller,
} from "Data";
import {
    exportFilterMembers,
    filterMembers,
    filterMembersCount,
    getMembers,
    getMembersCount,
    getSegmentCategories,
} from "Services";
import { buildFilterAttributes, buildMemberFilterConfig, getMongoDBQuery } from "Utils";

const MembersContext = React.createContext();

const resetMemberStatus = {
    memberList: [],
    limit: 25,
    skip: 1,
    isLoading: true,
    totalCount: 0,
    countMembers: false,
};

const resetSegmentMemberStatus = {
    memberList: [],
    limit: 25,
    skip: 1,
    isLoading: false,
    totalCount: 0,
    countMembers: false,
};

const getResetStatus = (caller) => {
    switch (caller) {
        case "MEMBERS_VIEW":
            return resetMemberStatus;
        case "SEGMENTS_VIEW":
            return resetSegmentMemberStatus;
        default:
            throw new Error(`Unknown caller: ${caller}`);
    }
};

const initializeState = (caller) => ({
    segments: [],
    selectedSegmentId: null,
    sortDirection: "",
    sortBy: "",
    isLoadingSegmentCategories: true,
    segmentCategories: {},
    searchText: "",
    currentFilters: {},
    filterConfig: {},
    currentTransactionFilters: {},
    transactionFilterConfig: {},
    otherCategoryId: "",
    selectedLoyaltyCardNumber: "",
    isExporting: false,
    isFiltersApplied: false,
    ...getResetStatus(caller),
});

const MembersContextActions = {
    SET_MEMBERS: "setMembers",
    SET_IS_LOADING: "setIsLoading",
    SET_IS_EXPORTING: "setIsExporting",
    UPDATE_MEMBER: "updateMember",
    SET_SEGMENTS_CATEGORIES: "setSegmentCategories",
    SET_IS_LOADING_SEGMENTS: "setIsLoadingSegments",
    SEARCHING: "memberSearching",
    CHANGE_SEARCH_TEXT: "changeSearchText",
    SET_PAGINATION_INFO: "setPaginationInfo",
    MEMBER_DATA_RESET: "memberDataReset",
    SET_SORTING_ORDER: "setSortingOrder",
    SET_CURRENT_FILTERS: "setCurrentFilters",
    SET_FILTER_CONFIG: "setFilterConfig",
    SET_CURRENT_TRANSACTION_FILTERS: "setCurrentTransactionFilters",
    SET_TRANSACTION_FILTER_CONFIG: "setTransactionFilterConfig",
    SET_SELECTED_SEGMENT: "setSelectedSegment",
    UPDATE_SEGMENTS: "updateSegments",
    SET_CARD_NUMBER: "setCardNumber",
    SET_TOTAL_MEMBERS: "setTotalMembers",
    SET_IS_FILTERS_APPLIED: "setIsFiltersApplied",
};


const createReducer = (caller) => (state, action) => {
    switch (action.type) {
        case MembersContextActions.SET_MEMBERS: {
            const newState = {
                ...state,
                isLoading: false,
                memberList: action.members.items,
            };
            if (action.shouldReset) {
                newState.skip = 1;
            }
            return newState;
        }
        case MembersContextActions.SET_TOTAL_MEMBERS: {
            return {
                ...state,
                totalCount: action.totalCount,
                countMembers: false,
            };
        }
        case MembersContextActions.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.status,
                countMembers: !!action.countMembers,
            };
        }
        case MembersContextActions.SET_IS_LOADING_SEGMENTS: {
            return {
                ...state,
                isLoadingSegmentCategories: action.status,
            };
        }
        case MembersContextActions.SET_SEGMENTS_CATEGORIES: {
            return {
                ...state,
                isLoadingSegmentCategories: false,
                segmentCategories: action.categories || {},
                otherCategoryId: action.otherCategoryId || "",
            };
        }

        case MembersContextActions.UPDATE_MEMBER: {
            return {
                ...state,
                memberList: state.memberList.map((item) => {
                    if (item._id === action.member._id) {
                        return action.member;
                    }
                    return item;
                }),
            };
        }
        case MembersContextActions.CHANGE_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText,
                ...(state.searchText !== action.searchText
                    ? { ...resetMemberStatus }
                    : {}),
            };
        }
        case MembersContextActions.SET_PAGINATION_INFO: {
            if (action.limit) {
                return { ...state, limit: action.limit };
            } else {
                return { ...state, skip: action.skip };
            }
        }
        case MembersContextActions.MEMBER_DATA_RESET: {
            return {
                ...state,
                memberList: [],
                limit: action.limit,
                skip: action.skip,
                isLoading: true,
            };
        }
        case MembersContextActions.SET_SORTING_ORDER: {
            return {
                ...state,
                sortDirection: action.sortDirection,
                sortBy: action.sortBy,
                memberList: [],
                skip: 1,
                isLoading: true,
            };
        }
        case MembersContextActions.SET_CURRENT_FILTERS: {
            return {
                ...state,
                currentFilters: action.filters || {},
                searchText: "",
                ...getResetStatus(caller),
            };
        }
        case MembersContextActions.SET_FILTER_CONFIG: {
            return {
                ...state,
                filterConfig: action.filterConfig,
            };
        }
        case MembersContextActions.SET_TRANSACTION_FILTER_CONFIG: {
            return {
                ...state,
                transactionFilterConfig: action.transactionFilterConfig,
            };
        }

        case MembersContextActions.SET_CURRENT_TRANSACTION_FILTERS: {
            return {
                ...state,
                currentTransactionFilters: action.currentTransactionFilters || {},
                searchText: "",
                ...getResetStatus(caller),
            };
        }
        case MembersContextActions.SET_SELECTED_SEGMENT: {
            return {
                ...state,
                selectedSegmentId: action.selectedSegmentId,
                currentFilters: action.currentFilters,
                currentTransactionFilters: action.currentTransactionFilters,
                searchText: "",
                ...getResetStatus(caller),
            };
        }
        case MembersContextActions.SET_CARD_NUMBER: {
            return {
                ...state,
                selectedLoyaltyCardNumber: action.selectedLoyaltyCardNumber,
            };
        }
        case MembersContextActions.SET_IS_FILTERS_APPLIED: {
            return {
                ...state,
                isFiltersApplied: action.status,
            };
        }
        case MembersContextActions.SET_IS_EXPORTING: {
            return {
                ...state,
                isExporting: action.status,
            };
        }
        default:
            return state;
    }
};

let searchTimeout;

const MembersContextProvider = (props) => {
    const { mainCaller, children } = props || {};
    const {
        isAuth,
        regionId,
        config,
        userConfigLoaded,
        selectedRegion,
        isAuthorizedForAction,
    } = useContext(UserContext);
    const {
        contactAttributes,
        transactionAttributes,
        tags,
        tiers,
        affinityGroups,
        merchantLocations,
        segments,
        loadSegments,
    } = useContext(DataContext) || {};

    const [state, dispatch] = useReducer(createReducer(mainCaller), initializeState(mainCaller));


    const loadMembers = useCallback(
        async ({ shouldReset = false, limit, skip, countMembers = false }) => {

            if (isAuthorizedForAction(AccessPermissionModuleNames.MEMBER, AccessPermissionModules[AccessPermissionModuleNames.MEMBER].actions.ListMembers)) {
                let newPromisesArray = [];
                const newLimit = limit || state.limit;
                const newSkip = ((skip || state.skip) - 1) * state.limit;
                let requestPayload = {};
                const isNotEmptyFilters = (state.currentFilters && Object.keys(state.currentFilters)?.length > 0) || (state.currentTransactionFilters && Object.keys(state.currentTransactionFilters)?.length > 0);
                try {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: true,
                        countMembers,
                    });
                    requestPayload = {
                        ...requestPayload,
                        payload: {
                            limit: newLimit,
                            skip: newSkip,
                            ...(state?.sortDirection ? { sortDirection: state.sortDirection?.toUpperCase() } : {}),
                            ...(state?.sortBy ? { sortBy: state.sortBy } : {}),
                            searchKey: state?.searchText,
                            regionId: regionId,
                            projection: config?.memberTableColumns,
                        },
                        ...(countMembers?{countPayload:{
                                regionId: regionId,
                                searchKey: state.searchText,
                            }}:{}),
                        ...(isNotEmptyFilters ? {
                            filters: {
                                ...(state.currentFilters && Object.keys(state.currentFilters)?.length > 0
                                    ? { membersFilter: getMongoDBQuery(state.currentFilters, state.filterConfig) } : {}),
                                ...(state.currentTransactionFilters && Object.keys(state.currentTransactionFilters)?.length > 0
                                    ? { transactionsFilter: getMongoDBQuery(state.currentTransactionFilters, state.transactionFilterConfig) }
                                    : {}),
                            },
                        } : {}),
                    };

                    newPromisesArray = [
                        ...newPromisesArray,
                        ...(isNotEmptyFilters ? [
                            await filterMembers(requestPayload?.payload, requestPayload?.filters),
                            ...(countMembers ? [await filterMembersCount(
                                {
                                    regionId: regionId,
                                    searchKey: state.searchText,
                                },
                                requestPayload?.filters,
                            )] : []),
                        ] : [
                            await getMembers(requestPayload?.payload),
                            ...(countMembers ? [await getMembersCount(
                                {
                                    regionId: regionId,
                                    searchKey: state.searchText,
                                },
                            )] : []),
                        ]),
                    ];

                    const [contactResponse, contactCountResponse] = await Promise.all(newPromisesArray);
                    console.log(contactResponse, contactCountResponse,newPromisesArray)

                    dispatch({
                        type: MembersContextActions.SET_MEMBERS,
                        members: contactResponse?.data || [],
                        shouldReset,
                    });
                    dispatch({
                        type: MembersContextActions.SET_TOTAL_MEMBERS,
                        totalCount: contactCountResponse?.data?.count || 0,
                    });

                } catch (e) {
                    console.error(e);
                    toast.error(
                        <div>
                            Failed to load members!
                            <br />
                            {e.message
                                ? `Error: ${e.message}`
                                : "Please try again later."}
                        </div>,
                    );
                } finally {
                    dispatch({
                        type: MembersContextActions.SET_IS_LOADING,
                        status: false,
                        countMembers: false,
                    });
                }
            } else {
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                    countMembers: false,
                });
            }
        },
        [
            regionId,
            isAuthorizedForAction,
            config.memberTableColumns,
            state.limit,
            state.skip,
            state.sortDirection,
            state.sortBy,
            state.searchText,
            state.currentFilters,
            state.filterConfig,
            state.currentTransactionFilters,
            state.transactionFilterConfig,
            dispatch,
        ],
    );

    const resetMember = useCallback(
        ({ skip, pageReset, limit }) => {
            if (pageReset) {
                dispatch({
                    type: MembersContextActions.MEMBER_DATA_RESET,
                    skip: skip,
                    limit: limit,
                });
            }
        },
        [dispatch],
    );

    const onChangePagination = useCallback(
        async ({ skip = state.skip, limit = state.limit }) => {
            if (state.limit !== limit) {
                dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    limit,
                });
                await loadMembers({
                    shouldReset: true,
                    limit,
                    skip: 1,
                    countMembers: false,
                });
            } else {
                // * If skip change, need to load the data manually without resetting
                dispatch({
                    type: MembersContextActions.SET_PAGINATION_INFO,
                    skip,
                });
                await loadMembers({ skip });
            }
        },
        [dispatch, state.skip, state.limit, loadMembers],
    );

    const setSortingOrder = useCallback(
        async ({ sortBy, sortDirection }) => {
            try {
                if (
                    sortDirection !== state.sortDirection ||
                    sortBy !== state.sortBy
                ) {
                    dispatch({
                        type: MembersContextActions.SET_SORTING_ORDER,
                        sortDirection: sortDirection,
                        sortBy: sortBy,
                    });
                }
            } catch (e) {
                console.error(e);
                dispatch({
                    type: MembersContextActions.SET_IS_LOADING,
                    status: false,
                });
            }
        },
        [dispatch, state.sortDirection, state.sortBy],
    );

    const loadSegmentsCategories = useCallback(
        async (silent = false) => {
            if (
                isAuthorizedForAction(
                    AccessPermissionModuleNames.SEGMENT,
                    AccessPermissionModules[AccessPermissionModuleNames.SEGMENT]
                        .actions.ListSegmentCaregories,
                )
            ) {
                try {
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: true,
                        });
                    }
                    const segmentCategoriesResponse =
                        await getSegmentCategories(regionId);

                    const transformedSegmentCategories =
                        segmentCategoriesResponse.items.reduce(
                            (result, { _id, name, createdOn, iconUrl }) => {
                                result[_id] = {
                                    _id,
                                    name,
                                    createdOn,
                                    segmentIds: new Set(),
                                    iconUrl,
                                };

                                return result;
                            },

                            {},
                        );
                    let otherCategoryId = "";
                    Object.keys(transformedSegmentCategories).forEach((key) => {
                        if (
                            transformedSegmentCategories[key].name === "Other"
                        ) {
                            otherCategoryId =
                                transformedSegmentCategories[key]._id;
                        }
                    });
                    dispatch({
                        type: MembersContextActions.SET_SEGMENTS_CATEGORIES,
                        otherCategoryId: otherCategoryId,
                        categories: transformedSegmentCategories,
                    });
                } catch (e) {
                    console.error(e);
                    if (!silent) {
                        dispatch({
                            type: MembersContextActions.SET_IS_LOADING_SEGMENTS,
                            status: false,
                        });
                    }
                }
            }
        },
        [regionId, isAuthorizedForAction, dispatch],
    );

    const updateSegments = useCallback(() => {
        loadSegmentsCategories(true);
        loadSegments(regionId);
    }, [regionId, loadSegments, loadSegmentsCategories]);

    const updateMember = useCallback(
        (member) => {
            dispatch({ type: MembersContextActions.UPDATE_MEMBER, member });
        },
        [dispatch],
    );

    const onChangeSearchText = useCallback(
        (searchText) => {
            dispatch({
                type: MembersContextActions.CHANGE_SEARCH_TEXT,
                searchText,
            });
        },
        [dispatch],
    );

    const setCurrentFilters = useCallback(
        (filters) => {
            dispatch({
                type: MembersContextActions.SET_CURRENT_FILTERS,
                filters,
            });
        },
        [dispatch],
    );

    const setFilterConfig = useCallback(
        (filterConfig) => {
            dispatch({
                type: MembersContextActions.SET_FILTER_CONFIG,
                filterConfig,
            });
        },
        [dispatch],
    );

    const setCurrentTransactionFilters = useCallback(
        (currentTransactionFilters) => {
            dispatch({
                type: MembersContextActions.SET_CURRENT_TRANSACTION_FILTERS,
                currentTransactionFilters,
            });
        },
        [dispatch],
    );


    const setTransactionFilterConfig = useCallback(
        (transactionFilterConfig) => {
            dispatch({
                type: MembersContextActions.SET_TRANSACTION_FILTER_CONFIG,
                transactionFilterConfig,
            });
        },
        [dispatch],
    );


    const selectSegment = useCallback(
        (segment, segmentId) => {
            dispatch({
                type: MembersContextActions.SET_SELECTED_SEGMENT,
                selectedSegmentId: segmentId,
                currentFilters: segment?.filter || segment?.memberFilter || {},
                currentTransactionFilters: segment?.transactionFilter || {},
            });
        },
        [dispatch],
    );

    const resetSegment = useCallback(() => {
            dispatch({
                type: MembersContextActions.SET_SELECTED_SEGMENT,
                selectedSegmentId: null,
                currentFilters: {},
                currentTransactionFilters: {},
            });
        },
        [dispatch],
    );

    const setSelectedLoyaltyCardNo = useCallback(
        (selectedLoyaltyCardNumber) => {
            dispatch({
                type: MembersContextActions.SET_CARD_NUMBER,
                selectedLoyaltyCardNumber,
            });
        },
        [dispatch],
    );

    const setIsFiltersApplied = useCallback(
        (status) => {
            dispatch({
                type: MembersContextActions.SET_IS_FILTERS_APPLIED,
                status,
            });
        },
        [dispatch],
    );

    const onExportMembers = useCallback(
        async (notificationEmails) => {
            try {
                dispatch({
                    type: MembersContextActions.SET_IS_EXPORTING,
                    status: true,
                });
                await exportFilterMembers(
                    {
                        sortDirection: state.sortDirection?.toUpperCase(),
                        sortBy: state.sortBy,
                        regionId: selectedRegion._id,
                        projection: config.memberTableColumns,
                        notificationEmails: notificationEmails,
                    },
                    {
                        ...(state.currentFilters &&
                        Object.keys(state.currentFilters)?.length > 0
                            ? { membersFilter: getMongoDBQuery(state.currentFilters, state.filterConfig) }
                            : {}),
                        ...(state.currentTransactionFilters &&
                        Object.keys(state.currentTransactionFilters)?.length > 0
                            ? { transactionsFilter: getMongoDBQuery(state.currentTransactionFilters, state.transactionFilterConfig) }
                            : {}),
                    },
                );
                toast.success(
                    "You will receive the exported file to the provided email",
                );
            } catch (e) {
                console.error(e);
                toast.error(
                    <div>
                        Failed to export member list!
                        <br />
                        {e.message
                            ? `Error: ${e.message}`
                            : "Please try again later."}
                    </div>,
                );
            } finally {
                dispatch({
                    type: MembersContextActions.SET_IS_EXPORTING,
                    status: false,
                });
            }
        },
        [
            selectedRegion,
            config.memberTableColumns,
            state,
            dispatch,
        ],
    );


    useEffect(() => {
        const shouldLoadMembers =
            (userConfigLoaded && mainCaller === MembersContextCaller.MEMBERS_VIEW) ||
            (userConfigLoaded &&
                mainCaller === MembersContextCaller.SEGMENTS_VIEW &&
                ((state.currentFilters && Object.keys(state.currentFilters).length > 0) || (state.currentTransactionFilters && Object.keys(state.currentTransactionFilters).length > 0))
            );

        if (shouldLoadMembers) {
            loadMembers({ shouldReset: true, skip: 1, countMembers: true });
        }
        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout);
            }
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // * Add only the dependencies which need to reset the current data
        // state.limit,
        state.sortBy,
        state.sortDirection,
        state.currentFilters,
        state.currentTransactionFilters,
        state.searchText,
        userConfigLoaded,
        config.memberTableColumns,
    ]);

    useEffect(() => {
        if (isAuth) {
            loadSegmentsCategories();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth, tiers, affinityGroups]);

    // * Auto update filterConfig
    useEffect(() => {
        if (Object.keys(contactAttributes)?.length > 0) {
            const memberFilterConfig = buildMemberFilterConfig({
                contactAttributes: buildFilterAttributes
                (AttributesCategoryGroup.CONTACT_ATTRIBUTES)
                ({
                    attributes: contactAttributes,
                    merchantLocations,
                    tiers,
                    affinityGroups,
                    selectedRegion,
                }),
                tags: tags || [],
                isLoading: state.isLoading,
            });
            setFilterConfig(memberFilterConfig);
        }
        if (Object.keys(transactionAttributes)?.length > 0) {
            const transactionFilterConfig = buildMemberFilterConfig({
                contactAttributes: buildFilterAttributes
                (AttributesCategoryGroup.TRANSACTION_ATTRIBUTES)
                ({
                    attributes: transactionAttributes,
                    merchantLocations,
                    tiers,
                    affinityGroups,
                    selectedRegion,
                }),
                tags: tags || [],
                isLoading: state.isLoading,
            });
            setTransactionFilterConfig(transactionFilterConfig);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactAttributes, merchantLocations, tiers, affinityGroups, selectedRegion, transactionAttributes, tags, state.isLoading]);

    const value = useMemo(
        () => ({
            ...state,
            segments,
            resetMember,
            resetSegment,
            setSortingOrder,
            onChangePagination,
            loadMembers,
            updateMember,
            onChangeSearchText,
            loadSegmentsCategories,
            setCurrentTransactionFilters,
            setTransactionFilterConfig,
            setCurrentFilters,
            setFilterConfig,
            selectSegment,
            updateSegments,
            setSelectedLoyaltyCardNo,
            setIsFiltersApplied,
            onExportMembers,
        }),
        [
            state,
            segments,
            loadMembers,
            loadSegmentsCategories,
            onChangePagination,
            onChangeSearchText,
            onExportMembers,
            resetMember,
            selectSegment,
            setCurrentTransactionFilters,
            setTransactionFilterConfig,
            setCurrentFilters,
            setFilterConfig,
            setSelectedLoyaltyCardNo,
            setSortingOrder,
            updateMember,
            updateSegments,
            setIsFiltersApplied,
            resetSegment,
        ],
    );

    return (
        <MembersContext.Provider value={value}>
            {children}
        </MembersContext.Provider>
    );
};


const MemberContextConsumer = MembersContext.Consumer;

export { MembersContext, MembersContextProvider, MemberContextConsumer };
